#brands {
    background: #212121;
    color: white;
    padding: 120px 0;
    text-align: center;
    margin-top: 80px;

    .brands__grid {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px;

        .brand__logo {
            display: flex;
            align-items: center;
            justify-self: center;
        }

        .brand__logo img {
            width: 150px;
            max-width: 100%;
        }
    }

    p {
        font-size: 35px;
        font-family: 'GDSage-Bold';
        margin-top: 70px;
    }

    hr {
        border: 1px solid grey;
        margin: 0 180px;
    }

    .brands__quote {
        text-align: center;
        margin-bottom: 40px;
    }

    @media (min-width: 900px) {
        .brands__grid {
            grid-template-columns: 1fr 1fr 1fr 1fr;

            .brand__logo img {
                margin: 40px 0;
            }
        }
    }
}
