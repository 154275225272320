#navbar {
    padding: 20px 0;

    .navbar__wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        button {
            background: none;
            border: none;
            text-transform: uppercase;
            cursor: pointer;
        }

        .navbar__wrapper__contact {
            text-align: left;

            .navbar__wrapper__contact__mobile {
                display: block;
            }
            .navbar__wrapper__contact__desktop {
                display: none;
            }

            @media (min-width: 900px) {
                .navbar__wrapper__contact__mobile {
                    display: none;
                }
                .navbar__wrapper__contact__desktop {
                    display: block;
                }
            }
        }

        .navbar__wrapper__logo {
            text-align: center;
        }

        .navbar__wrapper__hamburger {
            text-align: right;
        }
    }

    .navbar__leaf__left--1 {
        position: absolute;
        left: -100px;
        img {
            height: 200px;

            @media (min-width: 900px) {
                height: 400px;
            }
        }
    }

    .navbar__leaf__left--2 {
        position: absolute;
        top: 300px;
        left: -100px;
        img {
            height: 300px;

            @media (min-width: 900px) {
                height: 500px;
            }
        }
    }

    .navbar__leaf__right--1 {
        position: absolute;
        right: -100px;
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);

        img {
            height: 200px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            @media (min-width: 900px) {
                height: 400px;
            }
        }
    }

    .navbar__leaf__right--2 {
        position: absolute;
        top: 300px;
        right: -100px;
        z-index: 0;

        img {
            height: 300px;
            -webkit-transform: scaleX(-1);
            transform: scaleX(-1);

            @media (min-width: 900px) {
                height: 500px;
            }
        }
    }
}
