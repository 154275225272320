#loading {
    position: relative;
    overflow: hidden;

    .loading__black {
        background: #212121;
        height: 100vh;
    }

    .loading__text {
        display: none;

        @media (min-width: 900px) {
            position: absolute;
            left: -340px;
            top: 245px;
            display: flex;
        }
    }

    .loading__title {
        font-size: 130px;
        color: #fafafa;
        transform: rotate(-90deg);
        margin: 0;
    }

    .loading__leaf {
        position: absolute;
        right: -40px;
        bottom: -80px;
        transform: rotate(-79deg);

        img {
            transform: rotate(-79deg);
            height: 600px;
        }
    }
}
