#team {
    h2 {
        padding: 120px 0;
        text-align: center;
    }

    .team__grid {
        max-width: 100%;
        display: grid;
        grid-template-columns: 1fr;

        .team__grid__profile {
            margin: 0 10px;
            justify-self: center;

            img {
                max-width: 100%;
            }
        }
    }

    @media (min-width: 900px) {
        .team__grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
