footer {
    background: #212121;
    margin: 0;
    padding-top: 80px;
    color: white;

    a {
        text-decoration: none;
        color: white;
        display: block;
        margin: 13px 0;
    }

    .footer__socials {
        margin-left: 40px;
        margin-bottom: 40px;
        font-size: 40px;

        i,
        svg {
            margin-right: 20px;
        }
    }

    .footer__box__wrapper {
        display: grid;
        grid-template-columns: 1fr;
    }

    .footer__box {
        color: white;
        margin-left: 40px;
    }

    .footer__copyright {
        background: #212121;
        color: white;
        text-align: center;
        margin: 0;
        padding: 40px 0;
    }

    @media (min-width: 900px) {
        .footer__box__wrapper {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
        }
    }
}
