@font-face {
    font-family: 'GDSage-Bold';
    src: url('./fonts/GDSage-Bold.woff2') format('woff2'), url('./fonts/GDSage-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Acumin Variable Concept';
    src: url('./fonts/Acumin-Variable-Concept.woff2') format('woff2');
}

* {
    box-sizing: border-box;
}

html {
    color: #212121;
    font-family: 'Acumin Variable Concept';
    font-size: 14px;
    background: #fafafa;
    overflow-x: hidden;
}

a {
    text-decoration: none;
    color: inherit;
}

.container {
    margin: 0 auto;
    width: 80%;
    max-width: 1200px;
}

.title-spaced {
    font-size: 14px;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.fade-button {
    border: 1px solid #212121;
    color: #212121;
    padding: 10px 30px 8px;
    border-radius: 20px;
    text-transform: uppercase;
    background: #fafafa;
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        background: #212121;
        color: white;
        border: 1px solid white;
    }
}

.fade-grey-hover {
    transition: 0.5s;
    cursor: pointer;

    &:hover {
        color: grey;
    }
}

.site {
    overflow-x: hidden;
    position: relative;

    @media (min-width: 900px) {
        position: unset;
        overflow-x: unset;
    }
}
