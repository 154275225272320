#hero {
    text-align: center;
    padding-top: 120px;

    p {
        font-family: 'GDSage-Bold';
        font-size: 25px;
        text-align: left;
        margin: 0;

        span {
            color: grey;
        }
    }

    img {
        z-index: -1;
        top: 300px;
        left: -200px;
        width: 500px;
        position: absolute;
        transform: rotate(140deg);
    }

    @media (min-width: 900px) {
        p {
            font-family: 'GDSage-Bold';
            font-size: 35px;
            text-align: left;
            padding: 0 90px;
            margin: 0;
        }
    }

    @media (min-width: 1320px) {
        p {
            font-family: 'GDSage-Bold';
            font-size: 35px;
            text-align: left;
            padding: 0 90px;
            margin: 0;
        }
    }
}
