#work {
    text-align: center;

    h2 {
        padding: 120px 0;
    }

    .work__grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 40px 20px;
        z-index: 1;

        .work__grid__box {
            height: 600px;
            text-align: left;
            margin-bottom: 40px;

            &:hover {
                cursor: pointer;
            }
        }

        .work__grid__box__button {
            padding: 10px 30px 8px;
            border-radius: 20px;
            text-transform: uppercase;
        }

        .work__grid__box--white {
            color: white;

            .work__grid__box__overlay {
                padding: 40px 30px;
                height: 100%;
                transition: 0.8s;
                border: 1px solid #fafafa;

                span {
                    background: white;
                    color: #212121;
                }
            }
        }

        .work__grid__box--black {
            span {
                color: white;
                background: #212121;
            }

            .work__grid__box__overlay {
                padding: 40px 30px;
                height: 100%;
                transition: 0.8s;
                border: 1px solid white;
            }
        }

        h3 {
            font-size: 30px;
            font-family: 'GDSage-Bold';
        }
    }

    #work1 {
        background: linear-gradient(to bottom, rgba(33, 33, 33, 0.92), rgba(117, 19, 93, 0.01)),
            url('../assets/projects/FortyFiveN4.webp') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: relative;
    }

    #work2 {
        background: linear-gradient(to bottom, rgba(184, 184, 184, 0.92), rgba(117, 19, 93, 0.01)),
            url('../assets/projects/HomeKitchen3.webp') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        position: relative;
    }

    #work3 {
        background: url('../assets/projects/Kamura1.webp') no-repeat center center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    #work4 {
        background: linear-gradient(to bottom, rgba(33, 33, 33, 0.92), rgba(117, 19, 93, 0.01)),
            url('../assets/projects/AE2.webp') no-repeat center top;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .work__button__wrapper {
        text-align: center;
        margin: 80px 0 120px;
    }

    @media (min-width: 900px) {
        .work__grid {
            display: grid;
            grid-template-columns: 1fr 1fr;
        }

        .work__grid__box--white {
            .work__grid__box__overlay {
                &:hover {
                    background: white;
                    color: #212121;
                    border: 1px solid #212121;

                    span {
                        background: #212121;
                        color: white;
                    }
                }
            }
        }

        .work__grid__box--black {
            .work__grid__box__overlay {
                &:hover {
                    background: #212121;
                    color: white;

                    span {
                        background: white;
                        color: #212121;
                    }
                }
            }
        }
    }
}
