.menu__open {
    position: fixed;
}

#menu {
    background: #212121;
    color: #fafafa;
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 100;
    overflow: hidden;

    .menu__wrapper {
        position: relative;
        height: 100%;
    }

    .menu__navbar__wrapper__container {
        position: relative;
    }

    .menu__navbar__wrapper {
        position: absolute;
        left: 0;
        width: 100%;
        color: #fafafa;
        padding: 20px 0;
        z-index: 1000;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;

        button {
            background: none;
            border: none;
            text-transform: uppercase;
            color: #fafafa;
            cursor: pointer;
        }

        .menu__navbar__wrapper__contact {
            text-align: left;

            .menu__navbar__wrapper__contact__mobile {
                display: block;
            }
            .menu__navbar__wrapper__contact__desktop {
                display: none;
            }

            @media (min-width: 900px) {
                .menu__navbar__wrapper__contact__mobile {
                    display: none;
                }
                .menu__navbar__wrapper__contact__desktop {
                    display: block;
                }
            }
        }

        .menu__navbar__wrapper__logo {
            text-align: center;
        }

        .menu__navbar__wrapper__hamburger {
            text-align: right;
        }
    }

    .loading__text {
        display: none;

        @media (min-width: 900px) {
            position: absolute;
            left: -340px;
            top: 245px;
            display: flex;
        }
    }

    .loading__title {
        font-size: 130px;
        color: #fafafa;
        transform: rotate(-90deg);
        margin: 0;
    }

    .loading__leaf {
        position: absolute;
        right: -40px;
        bottom: -80px;
        transform: rotate(-79deg);

        img {
            transform: rotate(-79deg);
            height: 429px;

            @media (min-width: 1200px) {
                height: 600px;
            }
        }
    }

    .menu__items {
        position: absolute;
        font-size: 40px;
        max-width: 1000px;
        z-index: 1000;
        margin-top: 120px;
        padding: 0;

        li {
            list-style: none;
            display: inline-block;
            color: grey;
            padding-right: 41px;

            span {
                font-family: 'Acumin Variable Concept';
                color: #fafafa;
                transition: 0.3s;

                &:hover {
                    color: grey;
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        @media (min-width: 900px) {
            font-size: 80px;
            margin-top: 140px;
        }
    }
}
