#contact {
    background: #212121;
    color: white;
    padding: 0 40px;
    box-sizing: border-box;
    padding-bottom: 80px;

    h1 {
        padding: 120px 0;
        text-align: center;
        margin: 0;
    }

    label {
        display: block;
        margin-left: 15px;
        margin-bottom: 10px;
    }

    input,
    textarea {
        border-radius: 20px;
        padding: 10px 30px;
        box-sizing: border-box;
        width: 100%;
        border: none;
    }

    .contact__form {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 40px;
    }

    .contact__button {
        margin-top: 40px;
        text-align: center;
    }

    .fade__button__wrapper {
        text-align: left;
    }

    .contact__textarea {
        label {
            margin-top: 40px;
        }
    }

    .react-datepicker {
        border: 1px solid #f0f0f0 !important;
        font-family: 'Acumin Variable Concept';

        .react-datepicker__navigation {
            top: -2px !important;
        }

        .react-datepicker__triangle {
            transform: none !important;
            left: 90px !important;

            &:after {
                border-bottom-color: #f0f0f0 !important;
            }
        }
    }

    @media (min-width: 900px) {
        .contact__form {
            grid-template-columns: 1fr 1fr;
        }

        .fade__button__wrapper {
            text-align: right;
        }
    }
}
