#careers {
    padding-bottom: 120px;

    h2 {
        padding: 120px 0;
        text-align: center;
    }

    #careers__grid {
        display: grid;
        grid-template-columns: 1fr;

        .careers__grid__box {
            margin-left: 40px;
        }

        .careers__grid__box__office {
            font-weight: bold;

            h3 {
                display: inline;
            }

            img {
                height: 15px;
                padding-left: 5px;
            }
        }

        p {
            color: grey;
        }
    }

    @media (min-width: 900px) {
        #careers__grid {
            grid-template-columns: 1fr 1fr 1fr;
        }
    }
}
